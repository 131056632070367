import { FC } from 'react';
import IconProps from './IconProps';
import { dataAttributeProps } from '../../../utils/ComponentUtils';

const PlusIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 16 16"
      fill="currentColor"
      data-testid="icon-plus"
      xmlns="http://www.w3.org/2000/svg"
      {...dataAttributeProps(props)}
    >
      <path
        d="M9.00091 1C9.00091 0.447715 8.55319 0 8.00091 0C7.44863 0 7.00091 0.447715 7.00091 1H9.00091ZM7.00091 7.99998C7.00091 8.55226 7.44863 8.99998 8.00091 8.99998C8.55319 8.99998 9.00091 8.55226 9.00091 7.99998H7.00091ZM9.00091 8.00003C9.00091 7.44774 8.55319 7.00003 8.00091 7.00003C7.44863 7.00003 7.00091 7.44774 7.00091 8.00003H9.00091ZM7.00091 15C7.00091 15.5523 7.44863 16 8.00091 16C8.55319 16 9.00091 15.5523 9.00091 15H7.00091ZM8 7C7.44772 7 7 7.44771 7 8C7 8.55228 7.44772 9 8 9V7ZM15 9C15.5523 9 16 8.55228 16 8C16 7.44771 15.5523 7 15 7V9ZM7.99998 9C8.55226 9 8.99998 8.55228 8.99998 8C8.99998 7.44771 8.55226 7 7.99998 7V9ZM1 7C0.447715 7 0 7.44771 0 8C0 8.55228 0.447715 9 1 9V7ZM7.00091 1V7.99998H9.00091V1H7.00091ZM7.00091 8.00003V15H9.00091V8.00003H7.00091ZM8 9H15V7H8V9ZM7.99998 7H1V9H7.99998V7Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusIcon;
